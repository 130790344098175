.module-title {
    display: flex;
    flex-direction: row;
    align-items: center;
    font-size: 24px;
    font-weight: 600;
    margin-top: 12px;
    gap: 10px;
  }
  
  .module-title i {
    display: inline-block;
    width: 20px;
    height: 20px;
    color: var(--main-color);
  }


.promoted-goods-sec {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
  .promoted-container {
    width: 100%;
    max-width: 1156px;
    position: relative;
  }
  
  .scrollable-area {
    display: flex;
    flex-direction: row;
    gap: 20px;
    overflow: hidden;
  }
  .promoted-pager {
    z-index: 100;
    position: absolute;
    height: 100%;
    opacity: 0.5;
    padding: 0 10px;
  }
  .promoted-pager:disabled:hover {
    opacity: 0.5;
  }
  .promoted-pager i {
    display: block;
    width: 20px;
    height: 20px;
  }
  
  .promoted-pager:hover {
    opacity: 0.8;
  }
  #prevButton {
    left: 0;
    top: 0;
  }
  #nextButton {
    right: 0;
    top: 0;
  }

  .promoted-panel{
    display: flex;
    gap: 20px;
    padding: 20px 0;
  }

  @media only screen and (max-width: 600px) {
    .promoted-pager{
      display: none;
    }
    .mobile {
      display: flex;
    }
    .desktop {
      display: none;
    }

    .promoted-panel{
      scroll-snap-type: x mandatory;
      -webkit-overflow-scrolling: touch;
      overflow: auto;
      scrollbar-width: thin;
      scrollbar-color: #888 #f1f1f1;

      padding: 0;
      .panel{
        flex: 0 0 100%;
        scroll-snap-align: center;
      }
    }
}